import React from "react"
import styles from "../components/contact.module.scss"
import { Divider } from "../components/divider"
import headshot from "../images/headshot.jpg"
import { Button } from "./button"
import { graphql, StaticQuery } from "gatsby"
import serialize from "form-serialize"

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: { value: "", error: false },
      email: { value: "", error: false },
      goals: { value: "", error: false },
      formError: false,
      formSubmitted: false,
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((prevState) => ({
      [name]: { ...prevState[name], value: value },
    }));
  }

  validateForm = (form) => {
    let formError = false;
    for (const field in this.state) {
      if (form[field]) {
        if (form[field].value === "") {
          formError = true;
          this.setState((prevState) => ({
            [field]: { ...prevState[field], error: true },
          }));
        }
        else {
          this.setState((prevState) => ({
            [field]: { ...prevState[field], error: false },
          }));
        }
      }
    }
    if (formError === false) {
      this.setState({
        formError: false
      });
      return true;
    }
    else {
      this.setState({
        formError: true
      });
      return false;
    }
  }

  handleFormSubmit = event => {
    const form = event.target;
    const data = serialize(form);
    const xhr = new XMLHttpRequest();

    event.preventDefault();

    this.setState({
      formSubmitted: false
    }, () => {
      if (this.validateForm(form)) {
        xhr.open('POST', form.action);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = () => {
          if (xhr.status === 200) {
            this.setState({
              formSubmitted: true,
              name: { value: "", error: false },
              email: { value: "", error: false },
              goals: { value: "", error: false },
            });
          }
          else if (xhr.status !== 200) {
            alert('There was an error submitting your form, please try again.');
          }
        };
        xhr.send(data);
      }
    });
  }

  render() {
    return (
      <div id="contact" className={styles.cContact}>
        <hr className={styles.cContact__fade} />
        <div className={styles.cContact__sectionContainer}>
          <Divider />
          <section className={styles.cContact__flex}>
            <div className={styles.cContact__headshot}>
              <img src={headshot} alt="Valerie Levanduski" />
            </div>
            <div className={styles.cContact__intro}>
              <header className={styles.cContact__heading}>
                <h2>{this.props.data.contentfulContactSection.headline}</h2>
              </header>
              <div dangerouslySetInnerHTML={{ __html: this.props.data.contentfulContactSection.content.childMarkdownRemark.html }}></div>
            </div>
          </section>
          <form className={styles.cContact__contactForm} name="contact" method="post" data-netlify="true" onSubmit={this.handleFormSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <label>Name</label>
            <input type="text" name="name" value={this.state.name.value} onChange={this.handleInputChange} className={this.state.name.error ? styles.error : ''} />
            <label>Email</label>
            <input type="email" name="email" value={this.state.email.value} onChange={this.handleInputChange} className={this.state.email.error ? styles.error : ''} />
            <label>Goals</label>
            <textarea name="goals" value={this.state.goals.value} onChange={this.handleInputChange} className={this.state.goals.error ? styles.error : ''}></textarea>
            {this.state.formError && (
              <div className={`${styles.cContact__formMessage} ${styles.cContact__formMessageError}`}>Please correct the errors above and resubmit the form.</div>
            )}
            {this.state.formSubmitted && (
              <div className={`${styles.cContact__formMessage} ${styles.cContact__formMessageSuccess}`}>Thank you for your interest! I will contact you shortly regarding your inquiry.</div>
            )}
            <div className={styles.cContact__contactFormButtonContainer}>
              <Button size="large" buttonText="Submit" rounded={true} />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query contactQuery {
        contentfulContactSection {
          content {
            childMarkdownRemark {
              html
            }
          }
          headline
        }
      }`
    }
    render={data => <Contact data={data} {...props} />}
  />
)