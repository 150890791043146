import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import Content from "../components/content"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Pittsburgh Marketing Strategy Consultant"
      keywords={[
        `pittsburgh marketing`,
        `marketing consultant`,
        `marketing strategy`,
        `marketing campaigns`,
        `marketing manager`,
        `content manager`,
        `pittsburgh`,
        `cranberry`,
        `marketing`,
      ]}
    />
    <Intro />
    <Content />
    <Contact />
  </Layout>
)

export default IndexPage
