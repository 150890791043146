import React from "react"
import styles from "./divider.module.scss"
import { Logo } from "./logo"

const Divider = (props) => {
  return (
    <div className={styles.cDivider}>
      <hr className={styles.cDivider__leftRule} />
      <span className={styles.cDivider__logoContainer}>
        <Logo displayText={false} />
      </span>
      <hr className={styles.cDivider__rightRule} />
    </div>
  )
}

export { Divider }