import React from "react"
import styles from "../components/content.module.scss"
import { Divider } from "../components/divider"
import { graphql, StaticQuery } from "gatsby"

const Content = () => (
  <StaticQuery
    query={graphql`
      query contentQuery {
        contentfulIntroductionSection {
          content {
            childMarkdownRemark {
              html
            }
          }
          headline
        }
        contentfulServicesSection {
          content {
            childMarkdownRemark {
              html
            }
          }
          headline
        }
      }`
    }
    render={data => (
      <div id="content" className={styles.cContent}>
        <div className={styles.cContent__sectionContainer}>
          <Divider />
          <section>
            <header className={styles.cContent__heading}>
              <h2>{data.contentfulIntroductionSection.headline}</h2>
            </header>
            <div dangerouslySetInnerHTML={{ __html: data.contentfulIntroductionSection.content.childMarkdownRemark.html }}></div>
          </section>
          <section>
            <header className={styles.cContent__heading}>
              <h2>{data.contentfulServicesSection.headline}</h2>
            </header>
            <div dangerouslySetInnerHTML={{ __html: data.contentfulServicesSection.content.childMarkdownRemark.html }}></div>
          </section>
        </div>
      </div>
    )}
  />
)

export default Content