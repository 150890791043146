import PropTypes from "prop-types"
import React from "react"
import styles from "./intro.module.scss"
import { Logo } from "./logo"
import CaretIcon from "./caret"
import { Button } from "./button"
import { graphql, StaticQuery } from "gatsby";

const Intro = () => (
  <StaticQuery
    query={graphql`
      query taglineQuery {
        contentfulTagline {
          tagline {
            childMarkdownRemark {
              html
            }
          }
        }
      }`
    }
    render={data => (
      <section className={styles.cIntro}>
        <div className={styles.cIntro__buttonContainer}>
          <a href="#contact" title="Contact Me">
            <Button buttonText="Contact Me" rounded={true} />
          </a>
        </div>
        <div className={styles.cIntro__logoTaglineContainer}>
          <div>
            <span className={styles.cIntro__logo}>
              <Logo />
            </span>
            <div className={styles.cIntro__tagline} dangerouslySetInnerHTML={{ __html: data.contentfulTagline.tagline.childMarkdownRemark.html }}></div>
          </div>
        </div>
        <a href="#content" title="More" className={styles.cIntro__moreLinkContainer}>
          <div className={styles.cIntro__moreLinkContainerInner}>
            <span className={styles.cIntro__moreLink}>More</span>
            <br />
            <span className={styles.cIntro__caretDown}>
              <CaretIcon />
            </span>
          </div>
        </a>
      </section>
    )}
  />
)

Intro.propTypes = {
  siteTitle: PropTypes.string,
}

Intro.defaultProps = {
  siteTitle: ``,
}

export default Intro
