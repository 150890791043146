import React from "react"
import PropTypes from "prop-types"
import SvgLogo from "../images/valkyrie-marketing-logo.svg"
import styles from "./logo.module.scss"

const Logo = (props) => {
  return (
    <span className={`${styles.cLogo} ${!props.displayText ? styles.cLogoNoText : ''}`}>
      <SvgLogo />
    </span>
  )
}

Logo.defaultProps = {
  displayText: true,
};

Logo.propTypes = {
  displayText: PropTypes.bool,
}

export { Logo }
