import PropTypes from "prop-types"
import React from "react"
import styles from "./button.module.scss"

const Button = (props) => {
  const getClassName = () => {
    let cssClass = `${styles.cButton}`;
    cssClass += props.rounded ? ` ${styles.cButtonRounded}` : ''
    switch (props.size) {
      case 'normal':
        cssClass += ` ${styles.cButtonNormal}`;
        break;
      case 'large':
        cssClass += ` ${styles.cButtonLarge}`;
        break;
      case 'x-large':
        cssClass += ` ${styles.cButtonXLarge}`;
        break;
      default:
        break;
    }
    return cssClass;
  };
  return (
    <button className={getClassName()}>{props.buttonText}</button>
  )
}

Button.defaultProps = {
  size: 'normal'
}

Button.propTypes = {
  size: PropTypes.oneOf(['normal', 'large', 'x-large']),
  buttonText: PropTypes.string,
  rounded: PropTypes.bool,
}

export { Button }